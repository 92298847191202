import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useStatusProvider } from './StatusProvider';
import { PROVIDERS } from '../constants/common';
import { createBundler, createSmartAccountClient, PaymasterMode } from '@biconomy/account';
import { ethers } from 'ethers';

const createConfig = (privateKey, chainId = 80002) => ({
  privateKey,
  biconomyPaymasterApiKey: process.env.REACT_APP_BICONOMY_PAYMASTER_API_KEY,
  bundlerUrl: process.env.REACT_APP_BICONOMY_BUNDLER_URL,
  rpcUrl: PROVIDERS[chainId],
});

const BiconomyContext = createContext({
  biconomyTransferToken: () => Promise.resolve(undefined),
});

export function BiconomyProvider({ children }) {
  const [smartAccountAddress, setSmartAccountAddress] = useState();
  const { addMessage, setIsLoading } = useStatusProvider();

  const biconomyTransferToken = useCallback(
    async (privateKey, chainId, amount, recipientAddress = process.env.REACT_APP_ADMIN_ADDRESS) => {
      setIsLoading(true);
      try {
        const config = createConfig(privateKey, chainId);
        const bundler = await createBundler({
          bundlerUrl: config.bundlerUrl,
          chainId: chainId,
          userOpReceiptMaxDurationIntervals: {
            [chainId]: 60000,
          },
        });

        // Generate EOA from private key using ethers.js
        let provider = new ethers.providers.JsonRpcProvider(config.rpcUrl);
        let signer = new ethers.Wallet(config.privateKey, provider);

        // Create Biconomy Smart Account instance
        const smartWallet = await createSmartAccountClient({
          signer,
          biconomyPaymasterApiKey: config.biconomyPaymasterApiKey,
          // bundlerUrl: config.bundlerUrl,
          bundler,
        });
        const saAddress = await smartWallet.getAccountAddress();
        setSmartAccountAddress(saAddress);

        const amountInWei = ethers.utils.parseEther(amount).toHexString();
        // Get smart account balance
        const balance = await provider.getBalance(saAddress);
        const isInsufficientBalance = balance.lt(ethers.BigNumber.from(amountInWei));

        if (isInsufficientBalance) {
          throw new Error('Insufficient balance to make transaction');
        }

        const tx = {
          to: recipientAddress,
          data: '0x',
          value: amountInWei,
        };

        // Send the transaction and get the transaction hash
        const userOpResponse = await smartWallet.sendTransaction(tx, {
          paymasterServiceData: { mode: PaymasterMode.SPONSORED },
        });

        const { transactionHash } = await userOpResponse.waitForTxHash();
        // Wait for transaction confirmation
        const txReceipt = await provider.waitForTransaction(transactionHash);
        // Check if the transaction was successful
        if (txReceipt.status === 1) {
          addMessage('Transaction confirmed successfully');
          return transactionHash;
        } else {
          throw new Error('Transaction failed');
        }
      } catch (err) {
        addMessage(`Transaction failed: ${err?.message}`);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [addMessage, setIsLoading],
  );

  const providerValues = useMemo(
    () => ({
      smartAccountAddress,
      biconomyTransferToken,
    }),
    [smartAccountAddress, biconomyTransferToken],
  );

  return <BiconomyContext.Provider value={providerValues}>{children}</BiconomyContext.Provider>;
}

export function useBiconomyProvider() {
  return useContext(BiconomyContext);
}
