import { tokens } from './tokens';

export const CHAIN_LIST = {
  1: {
    env: ['production'],
    chainId: 1,
    displayName: 'Ethereum Mainnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://etherscan.io/'],
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[1]],
  },
  11155111: {
    env: ['develop', 'local', 'staging'],
    chainId: 11155111,
    displayName: 'Sepolia Testnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[11155111]],
  },
  1440001: {
    env: ['production'],
    chainId: 1440001,
    displayName: 'Immutable X',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://immutascan.io/'],
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[1440001]],
  },
  246: {
    env: ['production'],
    chainId: 246,
    displayName: 'Oasys Mainnet',
    currency: 'OAS',
    symbol: 'OAS',
    decimals: 18,
    blockExplorerUrls: ['https://explorer.oasys.games/'],
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[246]],
  },
  9372: {
    env: ['develop', 'local', 'staging'],
    chainId: 9372,
    displayName: 'Oasys Testnet',
    currency: 'OAS',
    symbol: 'OAS',
    decimals: 18,
    blockExplorerUrls: ['https://testnet-explorer.oasys.games/'],
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[9372]],
  },
};

export const NETWORK_CONFIGS = {
  1: {
    name: 'mainnet',
    networkId: 1,
    chainId: 1,
    hardfork: 'istanbul',
    type: 'mainnet',
    loginType: '',
  }, // Ethereum Mainnet

  56: {
    name: 'binance',
    networkId: 56,
    chainId: 56,
    hardfork: 'istanbul',
    type: 'mainnet',
  }, // Binance Smart Chain

  137: {
    name: 'matic',
    networkId: 137,
    chainId: 137,
    hardfork: 'istanbul',
    type: 'mainnet',
  }, // Polygon

  13371: {
    name: 'Immutable zkEVM',
    networkId: 13371,
    chainId: 13371,
    hardfork: 'istanbul',
    type: 'mainnet',
  }, // Immutable X

  13473: {
    name: 'Immutable zkEVM Testnet',
    networkId: 13473,
    chainId: 13473,
    hardfork: 'istanbul',
    type: 'mainnet',
  }, // Immutable X

  248: {
    name: 'oasys',
    networkId: 248,
    chainId: 248,
    hardfork: 'istanbul',
    type: 'mainnet',
  }, // Oasys Mainnet

  9372: {
    name: 'oasys-testnet',
    networkId: 9372,
    chainId: 9372,
    hardfork: 'istanbul',
    type: 'testnet',
  }, // Oasys Testnet
};

export const CURRENCY = {
  DM2: 'OAS',
  WEB3_AUTH: 'POL',
  IMMUTABLE: 'IMX',
};
