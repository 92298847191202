export const tokens = {
  wrappedNative: {
    1: {
      symbol: 'WETH',
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      decimals: 18,
      fractionDigits: 8,
    },
    11155111: {
      symbol: 'WETH',
      address: '0x178af04497F5A5eD9dF3f211023402Be436bB850',
      decimals: 18,
      fractionDigits: 8,
    },
    137: {
      symbol: 'WMATIC',
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      decimals: 18,
      fractionDigits: 8,
    },
    80002: {
      symbol: 'WMATIC',
      address: '0x07dBA930dd00504078bc23d52b26bf66f5E97afe',
      decimals: 18,
      fractionDigits: 8,
    },
  },
  usdt: {
    1: {
      symbol: 'USDT',
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      decimals: 6,
      fractionDigits: 6,
    },
    11155111: {
      symbol: 'USDT',
      address: '0x12f55D34b9759E850026636EaE48328c2E6D1017',
      decimals: 6,
      fractionDigits: 6,
    },
    137: {
      symbol: 'USDT',
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      decimals: 6,
      fractionDigits: 6,
    },
    80002: {
      symbol: 'USDT',
      address: '0x6F8cBaD3DfB11E181521872E70B1A1d745093d95',
      decimals: 6,
      fractionDigits: 6,
    },
  },
};

export const tokenTypeFilter = {
  bountyBall: "BOUNTY_BALL_NFT",
  hunter: "HUNTER_NFT",
  gauntlet: "GAUNTLET_NFT",
  genesis: "GENESIS_NFT",
};

export const listTrait = {
  treasure: "TREASURE_HUNTER",
  prize: "PRIZE_HUNTER",
  arena: "ARENA_HUNTER",
  merchant: "MERCHANT",
  mechanic: "MECHANIC",
};
