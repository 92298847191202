import { MainApi } from "./endpoint";

export function getBanners() {
  return MainApi.get("/user/banner?page=1&limit=20");
}

export function getCultures({ page, limit, langKey }) {
  return MainApi.get(
    `/user/culture?page=${page}&limit=${limit}&langKey=${langKey}`
  );
}

export function getTopOfCollections({ limit, langKey }) {
  return MainApi.get(`/user/collection/top?langKey=${langKey}&limit=${limit}`);
}

export function getCollectionList({ langKey, page, limit }) {
  return MainApi.get(
    `/user/collection?page=${page}&limit=${limit}&langKey=${langKey}`
  );
}

export function getSlashOrderStatus(params) {
  return MainApi.get("/users/order-status", params);
}
