import { Images } from '../../theme';
import { ButtonCustomWrapper, ButtonPlayGameWrapper, ButtonThirdPartyWrapper } from './styled';

export const ButtonThirdParty = ({ logo, children, ...props }) => {
  return (
    <ButtonThirdPartyWrapper {...props}>
      {logo && <img src={logo} className="button_logo" alt="icon" />}
      {children && <span className="button_title">{children}</span>}
    </ButtonThirdPartyWrapper>
  );
};

export const ButtonCustom = ({ color = 'blue', children, logo, ...props }) => {
  return (
    <ButtonCustomWrapper color={color} {...props}>
      {logo && <img src={logo} className="button_logo" alt="icon" />}
      {children && <span className="button_title">{children}</span>}
    </ButtonCustomWrapper>
  );
};

export const ButtonPlayGame = ({ color = 'blue', children, logo, ...props }) => {
  return (
    <ButtonPlayGameWrapper color={color} {...props}>
      <img src={Images.BTN_PLAY_GAME} className="button_image" alt="icon" />
      {children && <span className="button_title">{children}</span>}
    </ButtonPlayGameWrapper>
  );
};
