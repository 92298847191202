import { STORAGE, getLocalStorage, removeLocalStorage, setLocalStorage } from '../utils/storage';
import Request from '../utils/request';
import { openNewTab } from '../utils/redirect';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { LOGIN_TYPE } from '../constants/common';
import { usePassportProvider } from '../contexts/PassportProvider';
import Confirmable from '../components/confirmable';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

export const useAuth = () => {
  const { t } = useTranslation('auth');
  const { logout: web3AuthLogout } = useWeb3Auth();
  const { logout: logOutPassport } = usePassportProvider();
  const { auth } = useContext(MobXProviderContext);
  const location = useLocation();
  const history = useHistory()

  //Function to handle actions after successful login
  const onLoginAction = async (loginResult, publicAddress, userInfo, gameUrl) => {
    try {
      if (loginResult?.success) {
        const { data } = loginResult;
        const { code, id, access_token, login_type } = data;
        const { email, name } = userInfo;

        // Store user authentication details in local storage
        setLocalStorage(STORAGE.ACCESS_TOKEN, access_token);
        setLocalStorage(STORAGE.AUTH_CODE, code);
        setLocalStorage(STORAGE.USER_ID, id);

        // Set access token for future requests
        Request.setAccessToken(access_token);

        // Initialize user data in auth store
        auth.setInitialData({
          publicAddress,
          userId: id,
          email,
          userName: name,
        });

        if (location?.pathname === "/en/landing" || location?.pathname === "/jp/landing") {
          history.push(`/presale`);
        }

        // If game URL is provided, remove it from local storage and open it in a new tab
        if (Boolean(gameUrl)) {
          removeLocalStorage(STORAGE.GAME_URL);
          openNewTab(`${gameUrl}?auth_code=${code}&user_id=${id}&login_type=${login_type}`);
        }
      }
    } catch (error) {
      // Handle any errors that occur during the login action
      throw error;
    }
  };

  //Function to handle logout action
  const logoutAction = async (isLogoutFromGameSite = false) => {
    try {
      // Get the current login type from local storage
      const loginType = getLocalStorage(STORAGE.LOGIN_TYPE);
      // Perform the appropriate logout action based on the login type

      // Remove user authentication details from local storage
      removeLocalStorage(STORAGE.ACCESS_TOKEN);
      removeLocalStorage(STORAGE.PUBLIC_ADDRESS);
      removeLocalStorage(STORAGE.USER_ID);
      removeLocalStorage(STORAGE.AUTH_CODE);
      removeLocalStorage(STORAGE.LOGIN_TYPE);

      switch (loginType) {
        case LOGIN_TYPE.DM2:
          await web3AuthLogout();
          break;
        case LOGIN_TYPE.WEB3_AUTH:
          await web3AuthLogout();
          break;
        case LOGIN_TYPE.IMMUTABLE:
          await logOutPassport();
          break;
        default:
          await web3AuthLogout();
          break;
      }

      // Set authentication status to logged out
      auth.setLoggedIn(false);
      auth.setInitialData({
        publicAddress: '',
        userId: '',
        email: '',
        userName: '',
      });
      // Remove access token from request headers
      Request.removeAccessToken();

      // If game URL is provided, remove it from local storage and open it in a new tab
      if (isLogoutFromGameSite) {
        auth.toggleOpenModalLogin(true);
      }
    } catch (error) {
      Confirmable.open({
        content: <>{t('validation_messages:SOMETHING_WENT_WRONG')}</>,
        hideCancelButton: true,
      });
    }
  };

  return {
    onLoginAction,
    logoutAction,
  };
};
