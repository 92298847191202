import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal, { HeaderStyled } from '../modal';
import { useWeb3Auth } from '../../contexts/web3auth/web3auth';
import { BUTTON_CLOSE_ICON, PASSPORT_ICON, DM2C_ICON, COLOR_GOOGLE_ICON } from '../../theme/images';
import { ButtonCustom, ButtonThirdParty } from '../button-component';
import { TextShadowCustom } from '../text';
import { LOGIN_TYPE } from '../../constants/common';
import { usePassportProvider } from '../../contexts/PassportProvider';
import { waitAndOpenNextModal } from '../../hooks/useModal';

const ContentStyled = styled.div`
  padding: 12px 24px 24px;
  position: relative;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .label-email {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  .item-email {
    margin-bottom: 0px !important;
  }
  .input-email {
    margin-bottom: 0px;
    border: 2px solid #0099fe;
    box-shadow: none;
    .ant-input {
      color: #000000;
      font-weight: 700;
      font-size: 14px;
      &::placeholder {
        color: #cecfd3;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
  .form-btn {
    display: flex;
    flex-direction: column;
    /* gap: 16px !important; */
    margin-top: 10px;
    > button,
    > div {
      margin-bottom: 16px;
    }
  }
  .form-remember {
    display: flex;
    margin: 5px 0 0 1px;

    .form-remember-text {
      margin-left: 3px;
    }
  }
  .text-or {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: -8px 0px -8px;
    @supports (-webkit-text-stroke: 1.5px #000000) {
      color: white;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #000000;
      paint-order: stroke fill;
    }
  }
`;

// const CheckBoxStyled = styled(Checkbox)`
//   .ant-checkbox-inner {
//     width: 20px;
//     height: 20px;
//     border-radius: 6px;
//     border: 1px solid #0e69f2;
//     background-color: #0e7ff2;
//     box-shadow: none;
//   }
//   .ant-checkbox-checked {
//     border: none;
//     border-radius: 6px;
//     box-shadow: none;
//     .ant-checkbox-inner {
//       border: none;
//       border-radius: 6px;
//       box-shadow: none;
//       &::after {
//         border: none;
//         box-shadow: none;
//         width: 17px;
//         height: 14px;
//         background-image: url(${`${Images.CHECKED_ICON}`});
//         background-position: center;
//         background-repeat: no-repeat;
//         background-size: cover;

//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//       }
//     }
//   }
// `;

const LoginModal = ({ isOpen, onClose = () => {}, setModalToast, authStore }) => {
  const { t } = useTranslation('auth');
  const [loading, setLoading] = useState(false);
  const { login: loginPassportAsync } = usePassportProvider();
  const { toggleOpenDM2CModalLogin, getRulesLogin, loginMethodsActive } = authStore;
  // const formik = useFormik({
  //   initialValues: { email: getLocalStorage(STORAGE.EMAIL_REMEMBER), remember: getLocalStorage(STORAGE.EMAIL_REMEMBER) ? true : false },
  //   validationSchema: yup.object().shape({
  //     email: yup
  //       .string()
  //       // eslint-disable-next-line
  //       .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, t('validation_messages:EMAIL_INVALID'))
  //       .email(t('validation_messages:EMAIL_INVALID'))
  //       .max(40, t('validation_messages:MAX_LENGTH_30'))
  //       .required(t('validation_messages:EMAIL_REQUIRED')),
  //   }),
  //   enableReinitialize: true,
  //   onSubmit: ({ email, remember }) => {
  //     handleSubmitLogin(email, remember);
  //   },
  // });

  const { loginAuth0EmailPasswordLess, loginGoogle } = useWeb3Auth();

  // const { touched, handleSubmit, values, isValid, errors, setFieldValue, setFieldTouched } = formik;

  const handleCloseModal = () => {
    // formik.resetForm({});
    if (onClose) onClose();
  };

  // const handleSubmitLogin = async value => {
  const handleSubmitLogin = async () => {
    try {
      setLoading(true);
      await loginAuth0EmailPasswordLess(LOGIN_TYPE.WEB3_AUTH);
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoginGoogle = async () => {
    try {
      setLoading(true);
      await loginGoogle(LOGIN_TYPE.WEB3_AUTH);
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoginWithPassport = async () => {
    try {
      setLoading(true);
      const res = await loginPassportAsync();
      if (res instanceof Error) {
        throw res;
      }
    } catch (error) {
      if (error.type === 'AUTHENTICATION_ERROR') {
        onClose();
        return;
      }
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      onClose();
      setLoading(false);
    }
  };

  const handleCheckRulesLogin = async () => {
    try {
      setLoading(true);
      await getRulesLogin();
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleCheckRulesLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          if (!loading) handleCloseModal();
        }}
        destroyOnClose
        className="custom-modal"
        padding={0}
        title={null}
        width={500}
        color="blue"
        maskClosable={false}
        closeIcon={<img className="icon-close" src={BUTTON_CLOSE_ICON} alt="icon" />}
      >
        <Spin spinning={loading}>
          <HeaderStyled color="white">
            <TextShadowCustom>{t('login.login')}</TextShadowCustom>
          </HeaderStyled>
          <ContentStyled>
            <FormContent>
              {/* <Form.Item
                className="item-email"
                validateStatus={errors?.email && touched?.email ? 'error' : ''}
                help={
                  <div dangerouslySetInnerHTML={{ __html: touched?.email && errors?.email ? errors?.email : '' }} />
                }
              >
                <InputType
                  className="input-email"
                  prefix={
                    <img src={values?.email ? EMAIL_BLACK : EMAIL_GRAY} className="site-form-item-icon" alt="icon" />
                  }
                  placeholder={t('login.please_enter_email')}
                  name="email"
                  onChange={text => {
                    setFieldValue && setFieldValue('email', text.target.value || '');
                  }}
                  value={values?.email}
                  onBlur={() => setFieldTouched('email', true)}
                  disabled={loading}
                />
              </Form.Item>
              <div className="form-remember">
                <CheckBoxStyled
                  onChange={text => {
                    setFieldValue && setFieldValue('remember', text.target.checked);
                  }}
                  value={values?.remember}
                  isChecked={values?.remember}
                  checked={values?.remember}
                  colorScheme="teal"
                />
                <HeavyTextBlack className="form-remember-text">{t('login.remember_me')}</HeavyTextBlack>
              </div> */}
              <div className="form-btn">
                {loginMethodsActive.LOGIN_EMAIL && (
                  <ButtonCustom color="blue" disabled={loading} onClick={handleSubmitLogin}>
                    {t('login.login_with_email')}
                  </ButtonCustom>
                )}
                {loginMethodsActive.LOGIN_GOOGLE && (
                  <ButtonThirdParty logo={COLOR_GOOGLE_ICON} disabled={loading} onClick={handleLoginGoogle}>
                    {t('login.sign_in_goole')}
                  </ButtonThirdParty>
                )}
                <TextShadowCustom>{t('login.or')}</TextShadowCustom>

                {loginMethodsActive.LOGIN_IMMUTABLE && (
                  <ButtonThirdParty logo={PASSPORT_ICON} disabled={loading} onClick={handleLoginWithPassport}>
                    Immutable
                  </ButtonThirdParty>
                )}
                {loginMethodsActive.LOGIN_DM2 && (
                  <ButtonThirdParty
                    logo={DM2C_ICON}
                    disabled={loading}
                    onClick={() => {
                      waitAndOpenNextModal(onClose, toggleOpenDM2CModalLogin);
                    }}
                  >
                    DM2C
                  </ButtonThirdParty>
                )}
              </div>
            </FormContent>
          </ContentStyled>
        </Spin>
      </Modal>
    </>
  );
};

export default LoginModal;
