import { Form, Spin } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import Modal, { HeaderStyled } from '../modal';

import InputType from '../inputType';
import { ButtonThirdParty } from '../button-component';
import { BUTTON_CLOSE_ICON, EMAIL_GRAY, PASSPORT_ICON, COLOR_GOOGLE_ICON } from '../../theme/images';
import { BoldTextWhiteWithStroke } from '../text';
import { usePassportProvider } from '../../contexts/PassportProvider';

const ContentStyled = styled.div`
  padding: 12px 24px 24px;
  position: relative;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .label-email {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .input-email {
    margin-bottom: 0px;
    background: #202020 !important;
    border: 2px solid #727272;
    box-shadow: none;
    .ant-input {
      color: #ffffff;
      background: transparent;
      font-weight: 700;
      font-size: 14px;
      &::placeholder {
        color: #cecfd3;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
  .form-btn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: -12px;
  }
  .text-or {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: -8px 0px -8px;
    @supports (-webkit-text-stroke: 1.5px #000000) {
      color: white;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #000000;
      paint-order: stroke fill;
    }
  }

  .content {
    display: grid;
    grid-column: 1;
    justify-items: center;

    .linear_text {
      font-size: 16px;
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif;
      background: linear-gradient(90deg, rgba(236, 190, 225, 1) 0%, rgba(163, 238, 248, 1) 100%);
      background-clip: inherit;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
    .description {
      color: white;
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

const LoginPassportModal = ({ isOpen, onClose = () => {}, setModalToast }) => {
  const { t } = useTranslation('auth');
  const [loading, setLoading] = useState(false);
  const { login: loginPassportAsync } = usePassportProvider();
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object().shape({
      email: yup
        // eslint-disable-next-line
        .string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, t('validation_messages:EMAIL_INVALID'))
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(40, t('validation_messages:MAX_LENGTH_30'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
    }),
    enableReinitialize: true,
    onSubmit: () => {
      handleLoginWithPassport();
    },
  });

  const { touched, values, isValid, errors, setFieldValue, setFieldTouched } = formik;

  const handleCloseModal = () => {
    formik.resetForm({});
    if (onClose) onClose();
  };

  const handleLoginWithPassport = async () => {
    try {
      setLoading(true);
      await loginPassportAsync();
      onClose();
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          if (!loading) handleCloseModal();
        }}
        destroyOnClose
        className="custom-modal"
        padding={0}
        title={null}
        width={500}
        color="black"
        closeIcon={<img className="icon-close" src={BUTTON_CLOSE_ICON} alt="icon" />}
      >
        <Spin spinning={loading}>
          <HeaderStyled color="black">
            <BoldTextWhiteWithStroke>{t('login.login_with_immutable')}</BoldTextWhiteWithStroke>
          </HeaderStyled>
          <ContentStyled>
            <FormContent>
              <div className="content">
                <img src={PASSPORT_ICON} alt="icon" width={55} height={55} />
                <span className="linear_text">{t('login.immutable_passport')}</span>
                <span className="description">{t('login.subtitle_login_immutable')}</span>
              </div>
              <ButtonThirdParty
                logo={COLOR_GOOGLE_ICON}
                disabled={!isValid || loading}
                onClick={handleLoginWithPassport}
              >
                {t('login.sign_in_goole')}
              </ButtonThirdParty>
              <div className="text-or">{t('login.or')}</div>
              <Form.Item
                validateStatus={errors?.email && touched?.email ? 'error' : ''}
                help={
                  <div dangerouslySetInnerHTML={{ __html: touched?.email && errors?.email ? errors?.email : '' }} />
                }
              >
                <InputType
                  className="input-email"
                  prefix={<img src={EMAIL_GRAY} className="site-form-item-icon" alt="icon" />}
                  placeholder={t('login.please_enter_email')}
                  name="email"
                  onChange={text => {
                    setFieldValue && setFieldValue('email', text.target.value || '');
                  }}
                  value={values?.email}
                  onBlur={() => setFieldTouched('email', true)}
                  disabled={loading}
                />
              </Form.Item>
            </FormContent>
          </ContentStyled>
        </Spin>
      </Modal>
    </>
  );
};

export default LoginPassportModal;
