import { inject, observer } from 'mobx-react';
import React from 'react';
// import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';
// import Media from '../utils/media';
// import { Select } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { getNetworks } from '../utils/auth';
import { useAuth } from '../hooks/auth';
// import { Colors } from '../theme';

const env = process.env.REACT_APP_ENV;
export const chainOptions = getNetworks(env)?.map(item => ({ value: item.chainId, label: item.displayName }));

function ChainBox({ authStore, currentChainId, loggedIn, onSelected }) {
  // const { t } = useTranslation('auth');
  const history = useHistory();
  const { logoutAction } = useAuth();
  const { logout: web3AuthLogout } = useWeb3Auth();
  // const location = useLocation();
  const {
    logout,
    initialData: { publicAddress },
  } = authStore;

  React.useEffect(() => {
    const onMetamaskListener = () => {
      if (window?.ethereum) {
        const allwedNetworks = chainOptions.map(option => option?.value?.toString());
        window.ethereum.on('networkChanged', async function (networkId) {
          // Time to reload your interface with the new networkId
          // eslint-disable-next-line no-console
          console.log('network changed: ', networkId);

          if (!allwedNetworks.includes(networkId)) {
            try {
              await web3AuthLogout();
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log('catch: ', { error });
            } finally {
              logoutAction();
              history.push('/');
            }
          }
        });
      }
    };

    const removeMetamaskListener = () => {
      if (window?.ethereum) {
        window.ethereum.removeListener('accountsChanged', function (accounts) {
          // cleaner code
        });
        window.ethereum.removeListener('networkChanged', function (networkId) {
          // cleaner code
        });
      }
    };

    if (loggedIn) {
      // const web3authAdapter = localStorage.getItem('Web3Auth-cachedAdapter');
      // const isLMAdapter = web3authAdapter && web3authAdapter === WALLET_ADAPTERS.METAMASK;
      // setIsLoginMetaMaskAdapter(!!isLMAdapter);

      onMetamaskListener();
    }

    return () => removeMetamaskListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChainId, loggedIn, logout, publicAddress]);
  // }, [currentChainId, isLoginMetaMaskAdapter, loggedIn, logout, publicAddress]);

  if (!loggedIn) return null;
  // const isDisabled = location.pathname?.includes('/gacha');

  return (
    <></>
    // <ChainBoxStyled>
    //   <h2 className="label">{t('common:network')}</h2>
    //   <div className="field">
    //     <Select
    //       dropdownStyle={{ backgroundColor: 'rgb(0 29 73 / 90%)' }}
    //       value={currentChainId}
    //       style={{ width: 240, cursor: isDisabled ? 'no-drop' : 'pointer' }}
    //       // onChange={isLoginMetaMaskAdapter ? handleSelectChainByWeb3auth : handleSelectChainByMetamask}
    //       onChange={onSwitchNetwork}
    //       options={chainOptions}
    //       disabled={isDisabled}
    //     />
    //   </div>
    // </ChainBoxStyled>
  );
}

export default inject(stores => ({
  authStore: stores.auth,
}))(
  observer(({ authStore, ...props }) => {
    return (
      <ChainBox
        {...props}
        authStore={authStore}
        currentChainId={authStore.currentChainId}
        loggedIn={!!authStore?.initialData?.publicAddress || authStore?.loggedIn}
      />
    );
  }),
);
