import { Dropdown } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Clickable from '../components/clickable';
import Thumbnail from '../components/thumbnail';
import { Images } from '../theme';

const FlagItemBox = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 135px;
  img {
    margin-top: 2px;
    width: 24px;
    height: 24px;
  }
  .text-flag {
    font-weight: 900;
    font-size: 14px;
    color: #ffffff;
  }
`;

function UserBox({ authStore, onLogout, onOpenMyPage, publicAddress }) {
  const { t } = useTranslation(['common']);

  const handleLogout = e => {
    if (onLogout) onLogout(e);
  };

  const handleOpenMyPage = e => {
    if (onOpenMyPage) onOpenMyPage(e);
  };

  const OPTIONS_USER_BOX = [
    {
      label: t('header.my_page'),
      icon: Images.WHITE_PROFILE_ICON,
      onClick: handleOpenMyPage,
    },
    {
      label: t('header.logout'),
      icon: Images.WHITE_LOGOUT_ICON,
      onClick: handleLogout,
    },
  ];

  const renderDropdown = () => {
    return OPTIONS_USER_BOX?.map(item => ({
      key: item,
      label: (
        <FlagItemBox effect={false} onClick={item?.onClick}>
          <img src={item?.icon} alt="" />
          <div className="text-flag">{item?.label}</div>
        </FlagItemBox>
      ),
    }));
  };

  return (
    <Dropdown placement="bottomLeft" trigger="hover" menu={{ items: renderDropdown() }}>
      <Clickable className="language-box menu-item">
        <div className="_avatar-box">
          <Thumbnail
            rounded
            size={32}
            className="avatar-image"
            url={authStore.initialData.userImage}
            placeholderUrl={Images.USER_PLACEHOLDER}
          />
          {/* {Misc.trimPublicAddress(publicAddress || '', 6, 3)} */}
        </div>
      </Clickable>
    </Dropdown>
  );
}

// export default UserBox;
export default inject(stores => ({
  authStore: stores.auth,
}))(
  observer(({ authStore, ...props }) => {
    const { userName = '', email = '', publicAddress = '', userImage = '' } = authStore?.initialData;
    return (
      <UserBox
        {...props}
        authStore={authStore}
        userName={userName}
        email={email}
        publicAddress={publicAddress}
        userImage={userImage}
      />
    );
  }),
);
