import { Model } from '../utils/mobx-model-helper';
import { getUserInfo, updateUserInfo } from '../api/users';

const TYPES = {
  GET_USER_INFO: 1,
  UPDATE_USER_INFO: 2,
};

const UsersStore = Model.named('UsersStore').actions(self => ({
  getUserInfo(payload) {
    return self.request({
      type: TYPES.GET_USER_INFO,
      api: getUserInfo,
      payload,
    });
  },

  updateUserInfo(payload) {
    return self.request({
      type: TYPES.UPDATE_USER_INFO,
      api: updateUserInfo,
      payload,
    });
  },
}));

export { TYPES };
export default UsersStore.create();
