import { getSlashOrderStatus } from '../api/home';
import { Model } from '../utils/mobx-model-helper';

const TYPES = {
  GET_SLASH_ORDER_STATUS: 1,
};

const HomeStore = Model.named('HomeStore').actions(self => ({
  getSlashOrderStatus(params) {
    return self.request({
      type: TYPES.GET_SLASH_ORDER_STATUS,
      api: getSlashOrderStatus,
      payload: params,
    });
  },
}));

export { TYPES };
export default HomeStore.create();
