import { MainApi } from './endpoint';

export function getMyProducts(payload) {
  return MainApi.get('/user/product/owned', payload);
}

export function getProductDetails({ id, ...payload }) {
  return MainApi.post(`/product/${id}`, payload);
}

export function getProductCategories(payload) {
  return MainApi.get('/product/product-attributes', { ...payload, type: 'CATEGORY' });
}

export function getProductFilterData(payload) {
  return MainApi.get('/user/product/filter', payload);
}

export function getPriceRate(payload) {
  return MainApi.get('/coin-exchange-rate', payload);
}

export function getTransactionHistories({ productId, ...payload }) {
  return MainApi.get(`/user/product/history-order/${productId}`, payload);
}

export function favorite({ productId }) {
  return MainApi.post(`/user/product/interaction/${productId}`, { actionCode: 'WISH' });
}

export function getMyTransactionHistories({ ...payload }) {
  return MainApi.get('/user/product/transaction-history', payload);
}

export function getAuthorProductPrice({ productId, ...nickname }) {
  return MainApi.get(`/user/product/price/${productId}`, nickname);
}

export function getProductPrice({ productId }) {
  return MainApi.get(`/user/product/price/${productId}`);
}

export function getProductItems(payload) {
  return MainApi.get('/product/market-products', payload);
}

export function getProductMyPageItems(payload) {
  return MainApi.get('/users/user-products', payload);
}

export function buyByCoin(payload) {
  return MainApi.post('/users/buy-by-coin', payload);
}

export function buyByCard(payload) {
  return MainApi.post('/users/buy-by-card', payload);
}

export function buyByCrypto(payload) {
  return MainApi.post('/users/buy-by-crypto', payload);
}

export function sellProduct({ productId, ...payload }) {
  return MainApi.post(`/users/sell/${productId}`, payload);
}

export function cancelSellProduct({ productId }) {
  return MainApi.post(`/users/cancel-sell/${productId}`);
}
