import { Model } from '../utils/mobx-model-helper';
import {
  buyGenesisByCard,
  buyGenesisByCrypto,
  buyGenesisByNativeToken,
  confirmGenesisByNativeToken,
  getLatestPresale,
  getListMarketGenesis,
  requestBuyGenesisByNativeToken,
} from '../api/presale';

const TYPES = {
  GET_LIST_MARKET_GENESIS: 1,
  REQUEST_BUY_GENESIS_BY_NATIVE_TOKEN: 2,
  BUY_GENESIS_NATIVE_TOKEN: 3,
  COMFIRM_GENNESIS_BY_NATIVE_TOKEN: 4,
  BUY_GENNESIS_BY_CARD: 5,
  BUY_GENNESIS_BY_CRYPTO: 6,
};

const PresaleStore = Model.named('PresaleStore').actions(self => ({
  getListMarketGenesis(params) {
    return self.request({
      type: TYPES.BUY_GENNESIS_BY_CARD,
      api: getListMarketGenesis,
      payload: params,
    });
  },

  requestBuyGenesisByNativeToken(payload) {
    return self.request({
      type: TYPES.REQUEST_BUY_GENESIS_BY_NATIVE_TOKEN,
      api: requestBuyGenesisByNativeToken,
      payload,
    });
  },

  buyGenesisByNativeToken(payload) {
    return self.request({
      type: TYPES.BUY_GENESIS_NATIVE_TOKEN,
      api: buyGenesisByNativeToken,
      payload,
    });
  },

  confirmGenesisByNativeToken(payload) {
    return self.request({
      type: TYPES.COMFIRM_GENNESIS_BY_NATIVE_TOKEN,
      api: confirmGenesisByNativeToken,
      payload,
    });
  },

  buyGenesisByCard(payload) {
    return self.request({
      type: TYPES.BUY_GENNESIS_BY_CARD,
      api: buyGenesisByCard,
      payload,
    });
  },

  buyGenesisByCrypto(payload) {
    return self.request({
      type: TYPES.BUY_GENNESIS_BY_CRYPTO,
      api: buyGenesisByCrypto,
      payload,
    });
  },

  getLatestPresale(params) {
    return self.request({
      type: TYPES.BUY_GENNESIS_BY_CARD,
      api: getLatestPresale,
      payload: params,
    });
  },
}));

export { TYPES };
export default PresaleStore.create();
